import * as React from 'react'

//import WpApi from './wpapi'
import './css/HdaList.css'


interface MyProps {
    tup:any
}
type MyState = {
    mode:string
    inputvalue:string
}

class RmInvoerField extends React.PureComponent<MyProps,MyState> {
	
    constructor(props: MyProps) {
        super(props)

	this.click_value = this.click_value.bind(this)
	this.change_input_val = this.change_input_val.bind(this)
	this.input_submit = this.input_submit.bind(this)
	this.change_select = this.change_select.bind(this)
	this.on_key_up = this.on_key_up.bind(this)
	
	this.state = {
	    mode:'read',
	    inputvalue:''
	}
    }
    componentDidMount(){
	this.setState({inputvalue: this.props.tup.winner})
    }
    click_value(e:any){
	if(this.state.mode === 'read'){
	    this.setState({mode:'write'})
	}
    }
    render_edit_widget(){
	let tup = this.props.tup
	let value = tup.value
	let append = tup.append
	if(tup.cft_identifier.includes('num-input')){
	    return (
		<div className="input-with-btn">
		    <input value={this.state.inputvalue} onKeyUp={this.on_key_up}
		onChange={this.change_input_val} />
		    <span> {append} </span>
		    <img src={require("./img/save-icon-floppy.jpg")} onClick={this.input_submit} />
		 </div>
	    )
	}else if(tup.cft_identifier.includes('option-input')){
	    let choices = { 'kies': '- kies -', ...tup.choices}
	    let options = Object.entries(choices).map(([k,lab]:[string,any]) => (
		<option key={k} value={k}>{lab}</option>
	    ))
	    return (
		<select value={this.state.inputvalue}
		onChange={this.change_select}
		    >
		    {options}
		</select>
	    )
	}else{
	    return (<div> ? </div>)
	}
    }
    str_val(v:string){
	let tup = this.props.tup
	let cfid = tup.cft_identifier
	
	if(cfid.includes('option-input')){
	    return tup.choices[v]
	}
	return v
    }
    render_value(){
	let tup = this.props.tup
	let value = tup.value

	let append = tup.append
	if(tup.cft_identifier === 'txt-input-once'){
	    return (
	    <div className="value" onClick={this.click_value}>
		    {value}
		</div>
	    )
	}
	let cfid = tup.cft_identifier
	let strval = this.str_val( this.state.inputvalue)
	return this.state.mode === "read" ? (
	    <div className="value editable" onClick={this.click_value}>
		<span> {strval} </span> {append} 
		<img src={require("./img/edit-svg-icon-4.jpg")} />
		</div>
	) : (
		<div className="edit-widget">
		{this.render_edit_widget()}

	    </div>
	)
    }
    change_select(e:any){
	let v = e.target.value
	console.log(v)
	this.setState({
	    inputvalue : v,
	    mode:'read'
	})
    }
    on_key_up(e:any){
	if(e.keyCode === 13){
	    // Enter key was pressed
	    this.input_submit(null)
	}
    }
    change_input_val(e:any){
	let v = e.target.value
	if(this.props.tup.cft_identifier.includes('num-input')){
	    if(isNaN(Number(v))){
		return
	    }
	}
	console.log(v)
	this.setState({inputvalue : v})
    }
    input_submit(e:any){
	console.log('submit')
	this.setState({mode:'read'})
    }
    render(){
	let tup = this.props.tup
	let cfid = tup.cft_identifier
	let df = (<span></span>)

	let df_val = ""
	if(cfid.includes("num-input") && cfid.includes("global-default")){
	    df_val = tup.value
	}
	
	if(tup.default_value !== "" && tup.default_value !== false){
	    if(tup.default_value !== -1){
		df_val = tup.default_value
	    }
	}
	if(df_val !== "" && df_val !== "-1" && df_val){
	    let strval = this.str_val(df_val)
	    df = (<div className="default">default value: {strval}</div>)
	}
	return (
	    <div className="rm-invoer-field">
		<div className="label">{tup.label} {df}</div>
		<div className="value"> {this.render_value()}</div>
		<div className="comment"> </div>
	    </div>
	)
    }
}

export default RmInvoerField
