import * as React from 'react'

import InputLevel3 from './InputLevel3'
interface MyProps {
    devmode:boolean
    mode:string
}
type MyState = {
    view:string
}
class HdDash extends React.PureComponent<MyProps,MyState> {
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    view:'dash'
	}
    }

    render_topnav(){
	return (
	    <div className="dashboard-nav top-tab-nav">
		<div className="main-container">
		    <div className="layout-nav-items">
			<a href="#" className="nav-item active">Jouw dashboard</a>
			<a href="#" className="nav-item">Jouw stappenplan</a>
		    </div>
		</div>
	    </div>
	)
    }
    render_left_nav(){
	return (
	    <div className="content-area left sub-nav my-account-nav bg-gray-light zw-block-bg">
		<ul>
		    <li><a href="#">Home</a></li>
		    <li className="has-children collapsible">
			<a href="#" className="toggle">Alle stappen van het gas af</a>
			<ul className="submenu collapsible-content">
			    <li><a href="#">Zonnewarmte advies</a></li> 
			    <li><a href="#">Isolatie</a></li> 
			    <li><a href="#">Elektra</a></li> 
			    <li><a href="#">Opwekinstallaties</a></li> 
			    <li><a href="#">Aansluiting op Zonnewarmte</a></li> 
			</ul>
		    </li> 
		    <li><a href="#">Jouw offertes</a></li> 
		    <li><a href="#">Kenniscentrum & FAQ</a></li> 
		    <li><a href="#">Voordelen voor leden </a></li> 
		    <li><a href="#">Financiering & subsidies</a></li> 
		    <li><a href="#">Contact met Zonnewarmte</a></li> 
		    <li><a href="#">Help mee</a></li> 
		</ul>
	    </div>
	)
    }
    render_main(){
	    return (
		<div id="main" className="main-wide">
		    <div className="layout-main layout-main-sub-nav-with-right">
			{this.render_left_nav()}
			{this.render_main_center()}
			{this.render_right()}
		    </div>
		</div>
	    )
    }
    render_main_center(){
	return (
            <div className="content-area main">
                <div className="zw-block">
                    <h1>Hallo Jaime,</h1>
                    
                    <div className="text-container regular-text-container small-space-below">
                        <ul>
                            <li>Dit is jouw persoonlijke overzicht en actieplan voor jouw overstap naar Zonnewarmte.</li>
                            <li>Je kunt sommige stappen pas uitvoeren nadat je de voorgaande stap(pen) hebt afgerond. </li>
                            <li>Sommige stappen zijn niet afhankelijk van eerdere stap(pen).</li>
                        </ul>
                    </div>
		    
                </div>
		
                <div className="zw-block zw-block-bg bg-blue-light small-space-below">
		    
                    <div className="text-container regular-text-container small-space-below">
                        <span className="next-step to-be-checked">Eerstvolgende stap</span>
                        <h3>Laat jouw aanvraag checken door de Zonnewarmte-adviseur!</h3>
                        <ul className="checklist">
                            <li>Hulp bij het correct beantwoorden van detailvragen over je woning en energieverbruik</li>
                            <li>Isolatie-advies voor jouw woning</li>
                            <li>Inzicht of je woning al klaar is voor het Zonnewarmte-net</li>
                            <li>Adviesrapport met praktische tips voor besparing en te nemen maatregelen</li>
                            <li>Direct antwoord op al jouw vragen</li>
                        </ul>
                    </div>

                    <div className="zw-inner-block zw-inner-block-bg bg-white advisor-container small-space-below">
                        <div className="text-container smaller-text">
                            <h5>Eenmalige kosten € 50,-</h5>
                            <ul>
                                <li>Normaal € 350 maar met € 300 subsidie van de gemeente</li>
                                <li>Voor Haarlem Pashouders zelfs helemaal gratis</li>
                                <li>Bij aansluiting op het warmtenet krijg je de € 50 terug</li>
                            </ul>
                        </div>
                        <div className="person-container">
                            <div className="person-image">
                                <img src="img/adviseur.png" className="round-image img-extra-small" />
                            </div>
                            <div className="titles-container">
                                <h5>Jan van Galen</h5>
                                <h6>Zonnewarmte adviseur</h6>
                            </div>
                        </div>
                    </div>

                    <a href="#" className="button button-next element-space-below">Maak afspraak met Zonnewarmte adviseur</a>

                    <div className="zw-inner-block extra-actions">
                        <a  onClick={(e)=>{ this.setState({view:'input3'})}} >Zonnewarmte advies stap overslaan</a>
                        <span className="extra-text">Ons team checkt binnenkort de door jouw ingevulde gegevens</span>
                    </div>

                </div>
                
                <div className="zw-block small-space-below progress-steps">
                    <h3>Taken om nu op te pakken</h3>
                    <div className="openup-blocks progress-step-container">
                        <div className="collapsible progress-step done">
                            <h5 className="toggle progress-step-title">Check door ZW-adviseur</h5>
                            <div className="collapsible-content progress-step-content">
                                <p>Ik ben al zo enthousiast dat ik mee wil doen en Zonnewarmte Ramplaankwartier wil steunen met het tekenen van de deelname intentieverklaring.</p>
                            </div>
                        </div>
                    </div>
                    <div className="openup-blocks progress-step-container">
                        <div className="collapsible progress-step">
                            <h5 className="toggle progress-step-title">Woning voldoende isoleren</h5>
                            <div className="collapsible-content progress-step-content">
                                <p>Ik ben al zo enthousiast dat ik mee wil doen en Zonnewarmte Ramplaankwartier wil steunen met het tekenen van de deelname intentieverklaring.</p>
                            </div>
                        </div>
                    </div>
                    <div className="openup-blocks progress-step-container">
                        <div className="collapsible progress-step">
                            <h5 className="toggle progress-step-title">3-fase meterkast en elektrisch koken</h5>
                            <div className="collapsible-content progress-step-content">
                                <p>Ik ben al zo enthousiast dat ik mee wil doen en Zonnewarmte Ramplaankwartier wil steunen met het tekenen van de deelname intentieverklaring.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
	)
    }
    render_right(){
	return (
            <div className="content-area right mobile-bg-light-gray">
                <div className="zw-block zw-block-bg bg-white desktop-border-medium-light">
                    <h2>Benodigd budget</h2>
                    <table>
                        <tbody>
                            <tr>
                                <td className="title">Zonnewarmte advies</td>
                                <td className="amount">€ 50</td>
                            </tr>
                            
                            <tr>
                                <td>Overgaan op elektrisch koken</td>
                                <td>€ 480</td>
                            </tr>
                            
                            <tr>
                                <td>Opwekinstallaties</td>
                                <td>€ 1.250</td>
                            </tr>
                            
                            <tr>
                                <td>Leidingwerk, omvormer, etc.</td>
                                <td>€ 6.550</td>
                            </tr>  

                            <tr>
                                <td>Aansluiting op buurtwarmtenet</td>
                                <td>€ 2.250</td>
                            </tr>   

                        </tbody>

                        <tfoot>

                            <tr className="totals">
                                <td>Totaal</td>
                                <td>€ 10.580</td>
                            </tr>

                        </tfoot>
                        
                    </table>

                    <h3>Verwachte opbrengst p.j.</h3>
                
                    <table>

                        <tbody>

                            <tr>
                                <td className="title">Lorem ipsum dolor</td>
                                <td className="amount">€ 50</td>
                            </tr>
                            
                            <tr>
                                <td>Dolor sit amet</td>
                                <td>€ 112</td>
                            </tr>
                            
                            <tr>
                                <td>Loit dolor</td>
                                <td>€ 312</td>
                            </tr>
                            
                            <tr>
                                <td>Ipsum dolor sit</td>
                                <td>€ 509</td>
                            </tr>  

                            <tr>
                                <td>Solor sit amet</td>
                                <td>€ 245</td>
                            </tr>   

                        </tbody>

                        <tfoot>

                            <tr className="totals">
                                <td>Totaal</td>
                                <td>€ 1.297</td>
                            </tr>

                        </tfoot>
                        
                    </table>

                </div>
            </div>
	)
    }
    render(){
	if(this.state.view === 'dash'){
	    return (
		<div>
		    {this.render_topnav()}
		    {this.render_main()}
		</div>
	    )
	}
	else if(this.state.view === 'input3'){
	    return (
		<div>
		    <div onClick={(e)=>{this.setState({view:'dash'})}}> Terug naar Huisdossier</div>
		    <InputLevel3 devmode={this.props.devmode}/>
		</div>
	    )
	}
    }
}

export default HdDash

