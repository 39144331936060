import * as React from 'react'

import WpApi from '../wpapi'
import SummaryTable from './SummaryTable'

interface MyProps {
    devmode:boolean

}
type MyState = {
    view:string
    fields:any
    summary:any
    tab:string
    values:any
}
class InputLevel3 extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    view:'',
	    tab:'gasverbruik',
	    summary:{},
	    values:{},
	    fields:[]
	}
    }
    field_change(e:any,tup:any){
    }
    componentDidMount(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let path = '/hd-dash'
	let d : {[k:string]:any} = {
	    'mode': 'getfields'
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState({ fields: r.fields })
	})
    }

    render_formfield(tup:any,i:number){
	let tuptype = tup.type
	if(tup.classes.includes('radio')){
	    tuptype = 'radio'
	}
	let typeclass : {[k:string]:string}= {
	    'text' : 'zw-input-text',
	    'radio' : 'zw-radio',
	    'select' : 'zw-dropdown'
	}
	let ts :string = tuptype
	let cls = tup.name + " " + typeclass[ts]
	if(tup.type === "message"){
	    if(tup.label.startsWith('introtekst')){
		return (
		    <p key={i} className="intro-text"> {tup.message}</p>
		)
	    }
	}

	return (
	    <div key={i} className={"hd-input-block simple-view "+cls}>
		<div className="label-container">
		<label> {tup.label} </label>
		{this.render_input(tuptype,tup)}
	        <span className="msg"></span>
		</div>
	    </div>
	)
    }
    render_input(tuptype:string,tup:any){
	if(tuptype === "text"){
	    return (
		<input type="text"
		       name={tup.name}
		       value={this.state.values[tup.name]}
		onChange={(e:any)=>{this.field_change(e,tup)}}
		    placeholder={tup.placeholder}
		    />
	    )
	}
	else if(tuptype === "radio") {
	    let val = this.state.values[tup.name]

	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => {
		let k1 = 'skip-option-'+k
		if(tup.classes !== undefined && tup.classes.includes(k1)){return null}
		let rid = tup.name + '-' + k
		return (
		    <div className="radio-item">
			<input id={rid} type="radio"
		    checked={k === val}
		          onChange={(e:any)=>{this.field_change(e,tup)}}
		          name={tup.name} value={k} />
			<label htmlFor={rid}> {lab} </label>
			</div>
		)
	    })
	    return (<div className="radio-items-container">{opt_list}</div>)
	}
	else if(tuptype === "select") {
	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => {
		let k1 = 'skip-option-'+k
		if(tup.classes !== undefined && tup.classes.includes(k1)){return null}
		return (
		    <option key={i} value={k}> {lab} </option>
		)
	    })
	    return (
		<select className="narrow"
			name={tup.name}
			value={this.state.values[tup.name]}
		onChange={(e:any)=>{this.field_change(e,tup)}}		    
		    >
		    <option>Selecteer</option>
		    {opt_list}
		</select>
	    )
	}
	else if(tup.type === "group"){
	    return (
		<div className="group">
		{tup.fields.map((e:any) => (
		    <div className="subfield">
			<div className="label">{e.label}</div>
			{this.render_input(e.type,e)}
		    </div>
		))}

		</div>
	    )
	}
    }
    
    render_main(){
	let g = this.state.fields[this.state.tab]
	if(g === undefined){return null}
	return (
	    <div id="main" className="main-regular">
		<div className="layout-main layout-main-with-right child-mobile-bg">
		    <div className="content-area main">
			<div className="zw-block">
			    <div className="content-steps mobile-openup-blocks">
				<div className="content-step mobile-collapsible active" data-content-step="1">
				    <h2 className="mobile-toggle"> {g.label} </h2>
				    {g.fields.map((tup:any,i:number) => this.render_formfield(tup,i))}
				</div>
			    </div>
			</div>
		    </div>
		    <div className="content-area right mobile-bg-light-gray">
		       <SummaryTable data={this.state.summary}/>
		    </div>
		    

		</div>
	    </div>
	)
    }
    
    render_tab_nav(){
	return (
	    <div className="details-nav top-tab-nav">
		<div className="main-container">
		    <div className="layout-nav-items">
			{Object.entries(this.state.fields).map(([k,tup]:[string,any],i:number) => {
			    let cl = "nav-item details-nav-item"
			    if(k === this.state.tab){
				cl += " active"
			    }
			    let idx=i+1
			    return (<div onClick={(e)=>{this.setState({tab:k})}} className={cl} key={i}> {idx}. {tup.label}</div>)
			})}
		    </div>
		</div>
	    </div>    
	)
    }
    render(){
	return (
	    <div>
		{this.render_tab_nav()}
		{this.render_main()}
	    </div>
	    
	)

    }
    
}
export default InputLevel3
