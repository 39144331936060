import React from 'react';
import ReactDOM from 'react-dom/client'
import './css/index.css'
import HdBlockList from './HdBlockList'
import RmGlobals from './RmGlobals'
import RmInvoer from './RmInvoer'
import reportWebVitals from './reportWebVitals'
import WpApi from './wpapi'
import HdBlock from './HdBlock'
import RmSelectTech from './RmSelectTech'
import Loco from './Loco'
import User from './User'
import QuotationsRoot from './QuotationsRoot'
import HdSimpleBlock from './HdSimpleBlock'
import QuotationsAndCustomers from './QuotationsAndCustomers'
import AdviesAanvragenList from './AdviesAanvragenList'
import AanmeldFlow from './zwflow/AanmeldFlow'
import HdDash from './zwflow/HdDash'

// HdBlockList
let hd_elm = document.getElementById('react-root-hd-blocklist') as HTMLElement;
if(hd_elm !== null){
    let params = hd_elm.parentElement!.querySelector('.params')!.textContent;
    if(params === null){
	console.log('error: params not found')
    }else{
	let data = JSON.parse(params)
	WpApi.nonce = data.nonce
	if(data.loco !== undefined){
	    Loco.nl = data.loco
	}
	User.tup = data.user
	let pid = data.post_id
	const root = ReactDOM.createRoot(hd_elm)
	let blocktype = data.blocktype === undefined ? "normal" : data.blocktype

	let filter = data.filter === undefined ? {} : data.filter

	let hdv20 = ['rm_tech','rm_basis','rm_summary','mini_summary']
	if(data.view === "quotations"){
	    root.render(
		<React.StrictMode>
		    <QuotationsRoot
			is_simple_block={false}
			readonly_mode={false}
		      post_id={pid} user={data.user} view={data.view}/>
		    </React.StrictMode>
	    )
	}else if(hdv20.includes(data.view)){
	    root.render(
		<React.StrictMode>
		    <RmSelectTech  post_id={pid}
		devmode={data.devmode}
		techindex={data.techindex} user={data.user} blocktype={blocktype} view={data.view}
		filter={filter} />
		 </React.StrictMode>
	    )
	    
	}else if(data.view === "rm_invoer"){
	    root.render(
		<React.StrictMode>
		    <RmInvoer post_id={Number(pid)}/>
		    </React.StrictMode>
	    )
	    
	}else if(data.view === "rm_globals"){
	    root.render(
		<React.StrictMode>
		    <RmGlobals />
		    </React.StrictMode>
	    )

	}else{
	    root.render(
		<React.StrictMode>
		    <HdBlockList post_id={pid} user={data.user} blocktype={blocktype} view={data.view}  filter={filter} />
		    </React.StrictMode>
	    )
	}
    }
}
Array.from(document.getElementsByClassName("react-quotations-list")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{
	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data);
	    if(data.loco !== undefined){
		Loco.nl = data.loco
	    }
	    const root = ReactDOM.createRoot(elm)

	    root.render(
		<React.StrictMode>
		    <QuotationsAndCustomers
			query={data.query}
			bbgroup={data.group}
		    />
		    </React.StrictMode>
	    )
	}
    }
)
Array.from(document.getElementsByClassName("react-adviesaanvragen")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{
	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data);
	    if(data.loco !== undefined){
		Loco.nl = data.loco
	    }
	    const root = ReactDOM.createRoot(elm)

	    root.render(
		<React.StrictMode>
		    <AdviesAanvragenList
			bbgroup={data.group}
		    />
		    </React.StrictMode>
	    )
	}
    }
)


// HdBlock for hd-simple-block
Array.from(document.getElementsByClassName("react-root-hd-simple-block")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{
	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data);
	    const root = ReactDOM.createRoot(elm)
	    root.render(
		<React.StrictMode>
		    <HdSimpleBlock
			xpcondition={data.xpcondition} instr_field={data.instr_field}
			acfkey={data.acfkey} post_id={data.post_id} force_reload={data.force_reload}
		    />
		 </React.StrictMode>
	    )
	}	
    }
)

// HdBlock for single-block
Array.from(document.getElementsByClassName("react-root-hd-block")).forEach(
    function(elm, index, array) {
	let params = elm.parentElement!.querySelector('.params')!.textContent;
	if(params === null){
	    console.log('error: params not found')
	}else{

	    let data = JSON.parse(params)
	    WpApi.nonce = data.nonce
	    console.log(data)
	    if(data.loco !== undefined){
		Loco.nl = data.loco
	    }
	    User.tup = data.user
	    const root = ReactDOM.createRoot(elm)
	    if(data.view === "quotations"){
		let ro = data.readonly_mode === undefined ? false : data.readonly_mode
		root.render(
		    <React.StrictMode>
			<QuotationsRoot
			    is_simple_block={true}
			    readonly_mode={ro}
			    acfkey={data.acfkey}
			    post_id={data.post_id}
			    user={data.user} view={data.view}
			    supplier={data.supplier}
			/>
		    </React.StrictMode>
		)
	    }else{
		root.render(
		    <React.StrictMode>
			<HdBlock key="widget" params={data} post_id={data.post_id}  />
			</React.StrictMode>
		);
	    }
	}
    });

let afl_elm = document.getElementById('react-root-cft-aanmeldflow') as HTMLElement;
if(afl_elm !== null){
    let params = afl_elm.parentElement!.querySelector('.params')!.textContent;
    console.log(params);
    if(params !== null){
	let data = JSON.parse(params)
	if(data != null){
	    const root = ReactDOM.createRoot(afl_elm)

	    root.render(
		<React.StrictMode>
		    <AanmeldFlow mode={data.mode} devmode={data.devmode}/>
		    </React.StrictMode>
	    )
	}
    }
    
}
let dash_elm = document.getElementById('react-root-cft-hd-dash') as HTMLElement;
if(dash_elm !== null){
    let params = dash_elm.parentElement!.querySelector('.params')!.textContent;
    console.log(params);
    if(params !== null){
	let data = JSON.parse(params)
	if(data != null){
	    const root = ReactDOM.createRoot(dash_elm)

	    root.render(
		<React.StrictMode>
		    <HdDash mode={data.mode} devmode={data.devmode}/>
		    </React.StrictMode>
	    )
	}
    }
    
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
