import * as React from 'react';

import WpApi from './wpapi'
import Loader from './Loader'
import Loco from './Loco'
import User from './User'
import Conditional_dropdown from './Conditional_dropdown'

interface MyProps {
    aspect: string,  // aspect : huidig | voorstel
    tup: any,
    bptup?:any,
    label:string,
    permission:any,
    stup:any,        // parent record
    l2cluster:any,
    post_id:string,
    acfkey:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void
}


type MyState = {
    value_lab:string,
    value_count:number,
    winner: string,
    comments: Array<string>,
    comment_count: number,
    buttons: Array<any>,
    is_loading:boolean
};

class StepAspect extends React.PureComponent<MyProps,MyState> {
    
    private labels: { [string: string]: string } 	= {
	'huidig' :  Loco.tr('header_huidig'),
	'huidig_zwadvies': Loco.tr('header_huidig_zwadvies'),
	'voorstel' : Loco.tr('header_voorstel'),
    }
	
    get_aspect_header(){
	var aspect = this.props.aspect
	if(this.props.stup.context === "supplier-quotations"){
	    return aspect === "huidig" ? "volgens bewoners" : "volgens leverancier"
	}

	return  this.labels[aspect]
    }
    
    constructor(props: MyProps) {
        super(props)
        this.handle_value_click = this.handle_value_click.bind(this)
        this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_click_history = this.handle_click_history.bind(this)

	//this.render_choice = this.render_choice.bind(this)
	this.handle_dropdown_change = this.handle_dropdown_change.bind(this)
	this.state = {
	    value_lab : '',
	    value_count:0,
	    winner: '',
	    comments:[],
	    comment_count:0,
	    buttons: [],
	    is_loading:true
	}
    }
    get_pop_label(){
	//let label = this.props.stup.label
	let slab = this.props.stup.section_label
	
	var alabel = this.get_aspect_header()
	console.log(`alabel: ${alabel}`)

	if(this.props.label){
	    return (<span>{alabel}: {this.props.label}</span>)
	}else{
	    return alabel + ': ' + slab
	}
    }
    public handle_comment_click(e:any){
	// open the vote-popup for comments
	let lbl = this.get_pop_label()

	let bd = this.get_bp_slug()

	this.props.cb_parent({
	    cmd: 'comment-vote',
	    //tup:this.props.tup,
	    post_id: this.props.post_id,
	    label: lbl,
	    bouwdeel:bd,
	    level2:this.props.l2cluster.name,
	    acf_key_step: this.props.acfkey,
	    acf_key_select: this.props.tup.select.key,
	    aspect: this.props.aspect
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		//this.setState({comment: r.winner_txt, is_loading:false})
		console.log('set comments?')
		if(r.new_state){
		    this.setState({
			comments: r.new_state.map((x:any)=>x.value),
			comment_count: r.new_state.length,
			is_loading:false
		    })
		}
	    }
	})
    }
    get_bp_slug(){
	if(this.props.bptup === undefined
	    ||
	    this.props.bptup === null){
	    return null
	}
	return this.props.bptup.slug
    }
    public handle_value_click(e:any){
	let lbl = this.get_pop_label()
	console.log(e)
	let l2 = null

	if(this.props.l2cluster){
	    l2 = this.props.l2cluster.name
	}

	let bd = this.get_bp_slug()

	// open the vote-popup
	this.props.cb_parent({
	    cmd: 'choice-vote',
	    with_history:true,
	    history_popup: {
		post_id: this.props.post_id,
		acf_key: this.props.acfkey,
		choices:this.props.tup.choices,
		bouwdeel:bd,
		aspect: this.props.aspect,
		label: 'Historie',
		level2: l2
	    },
	    //tup:this.props.tup,
	    label: lbl,
	    level2: l2,
	    post_id: this.props.post_id,
	    acf_key_step: this.props.acfkey,
	    acf_key_select: this.props.tup.select.key,
	    is_final_field: this.props.tup.is_final_field,
	    bouwdeel: bd,
	    aspect: this.props.aspect
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		this.update_choice_winner( r.winner)
	    }
	})
    }

    update_choice_winner(value:string){
	this.setState({is_loading:false})
	if(value in this.props.tup.choices){
	    var lab = value === 'kies' ? '' : this.props.tup.choices[value]
	    this.setState({
		winner: value,
		value_count: this.props.tup.value_count,
		value_lab : lab
	    })
	}
	if(value==null){
	    console.log('value is nul')
	    this.setState({
		winner:'',
		value_lab:'',
		value_count:0
	    })
	}
    }



    componentDidMount(){
	// update state from props
	let atup = this.props.tup
	let bp = null
	let asp = this.props.aspect
	this.setState({is_loading:false})
	if(atup.active){
	    if('buttons' in atup){
		this.setState({buttons: atup.buttons.list})
	    }
	    if(this.props.bptup !== null
		&& this.props.bptup !== undefined
	      ){
		bp = this.props.bptup[asp]
		if(bp !== null && bp !== undefined && bp.row !== undefined){
		    this.update_choice_winner(bp.row.value)
		    let txt_array = bp.comments.map((x:any)=>x.value)
		    this.setState({
		    	comments: txt_array,
			comment_count: txt_array.length
		    })
		}
	    }else{
		if('value' in atup){
		    this.update_choice_winner(atup.value)
		}
	    }
	    if('comments' in atup){
		let txt_array = this.props.tup.comments.map((x:any)=>x.value)
		this.setState({
		    comments: txt_array,
		    comment_count: this.props.tup.comment_count
		})
	    }
	}
    }
    public handle_click_history(){
	console.log('click history')
	this.props.cb_parent({
	    cmd: 'open-history',
	    choices:this.props.tup.choices,
	    level2:this.props.l2cluster.name,
	    post_id: this.props.post_id,
	    acf_key: this.props.acfkey,
	    aspect: this.props.aspect
	},()=>{})

    }
    handle_dropdown_change(e:any){
	// remove focus (https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/blur)
	e.target.blur()  
	let slug = e.target.value
	console.log(e.target)
	let l2 = null

	if(this.props.l2cluster){
	    l2 = this.props.l2cluster.name
	}
	
	console.log(`changed dropdown to: ${slug} l2: ${l2}`)
	
	let d:{[name:string]:string}   = {
	    acf_key: this.props.acfkey,
	    votetype: 'choice',
	    level2: l2,
	    aspect: this.props.aspect,
	    post_id : this.props.post_id,
	    is_final_field: this.props.tup.is_final_field,
	 
	    value: slug
	}
	let bd = this.get_bp_slug()

	if(bd !== null){
	    d.bouwdeel = bd
	}

	console.log('set is loading true')
	this.setState({ is_loading:true})

	WpApi.do_post('/hd-attr',d, (r:any) => {
	    this.update_choice_winner( r.winner)
	    this.props.cb_parent({
		cmd: 'set-action-status',
		section_status: r.section_status,
		aspect: this.props.aspect,
		step: r.winner
	    },()=>{})

	})
	
    }


    public render_dropdown(){
	if(this.props.stup.context === "supplier-quotations"){
	    return null
	}
	if(this.state.buttons === undefined){
	    return null
	}
	
	let showall = this.props.tup.buttons.class === 'show-all-buttons'
	//let placeholder = this.props.aspect === 'huidig' ? '-wijzig status-' : '-wijzig advies-'

	let pre_text = 'Wijzig dit in'
	/*
	let pre_text = 
	    this.props.aspect === 'huidig' ? 'Wijzig dit in' : 'Wijzig dit advies in'
	if(this.props.stup.name === "uitvoering_status" && this.props.aspect === 'voorstel'){
	    pre_text = 'Wijzig dit in'
	}*/

	let filter = (key:string) => {
	    return key !== this.state.winner
	}
	// request
	if(this.props.permission.request){
	    if(this.props.permission.request.current_user){
		let rq_options = [
		    'aangevraagd',
		    'online_gevraagd'
		]
		filter = (key:string) => {
		    return rq_options.includes(key)
		}
	    }
	}

	return (new Conditional_dropdown()).select_with_cond_logic(
	    this.state.buttons,
	    showall,
	    this.state.winner,
	    '-kies-',
	    pre_text,
	    this.handle_dropdown_change,
	    filter
	)
    }

    public render(){
	let atup = this.props.tup
	if(atup.active){
	    return this.render_aspect()
	}else{
	    return (<div> </div>)
	}
    }
    render_comments(has_edit_perm:boolean){

	let leeg_txt = "(zet hier je opmerkingen)"
	if(this.props.stup.context === "supplier-quotations"){
	    leeg_txt = "(Bewoner kan hier opmerkingen zetten)"
	}

	    
	if(this.props.aspect === "voorstel"){
	    if(this.props.stup.name === "uitvoering_status" ){
		leeg_txt = "(Leverancier kan hier opmerkingen zetten)"
	    }else if(this.props.stup.name === "zw-advies"){
		leeg_txt = "(ZW-adviseur kan hier opmerkingen zetten)"
	    }else{
		leeg_txt = "(Extern adviseur kan hier opmerkingen zetten)"
	    }
	}

	
	if(this.state.comments.length==0 && has_edit_perm){
	    return (<div className="text empty"><i>{leeg_txt}</i></div>)
	}
	return (<div className="text">
				{this.state.comments.map((txt:string,j:number)=> {
				    let k = `t-${j}`
				    return (<div key={k} className="part" >{txt}</div>)
				})}
		</div>)
    }
    
    get_span_lab_val(label:string, value:string){
	if(value === ''){
	    return (<span> {label}: <b><i>(nog leeg)</i></b></span>)
	}else{
	    return (<span> {label}: <b>{value}</b></span>)
	}
    }
    
    get_value_text(){
	if(this.props.aspect === "huidig"){
	    return this.get_span_lab_val('Huidige status', this.state.value_lab);
	}else{
	    // voorstel / advies
	    let txt = 'Huidige status, volgens extern adviseur'
	    if(this.props.stup.name === "uitvoering_status"){
		txt = 'Huidige status, volgens leverancier'
	    }
	    if(this.props.stup.name === "zw-advies"){
		txt = 'Huidige status, volgens ZW-adviseur'
	    }
	    return this.get_span_lab_val(txt, this.state.value_lab);
	}
    }
    render_aspect(){
	let stup = this.props.stup
	var aspect = this.props.aspect
	// sometimes hide the right-hand 'voorstel'
	if(aspect === "voorstel" && stup.classes.includes('hide-voorstel')){
	    return (<div className="hidden"></div>)
	}
	let zw_a_label = this.labels.huidig_zwadvies;
	
	var label = (aspect === 'huidig' && stup.section_name === 'zw-advies') ? zw_a_label : this.get_aspect_header()
	var loader = (<Loader/>)
	let has_edit_perm = false
	let has_view_perm = false
	let has_req_perm = false

	if(this.props.permission){
	    if(this.props.permission.edit){
		has_edit_perm = this.props.permission.edit.current_user;
		has_view_perm = this.props.permission.view.current_user;
	    }
	    if(this.props.permission.request){
		if(this.props.permission.request.current_user){
		    has_req_perm = true
		}
	    }
	}

	if(aspect === 'voorstel' && ! has_edit_perm){
	    if(this.state.value_count === 0 && this.state.comment_count === 0){
		return (<div>  </div>)
	    }
	}
	
	if(! has_view_perm){
	    return (
	    	<div className="aspect">
		    <div className="header">
			<div className="label"> - -  </div> <span>- </span>
		    </div>
		    <div className="two-columns">  </div>
		</div>
	    )
	}

	/*
             <div className="header">
                 <div className="label"> VERVALT </div>
                 <span data-balloon="Toon historie"
                       data-balloon-pos="up" className="cf-icon-btn"
                       onClick={this.handle_click_history}><i className="bb-icon-l bb-icon-list"/></span>
                 
             </div>

      */
	return (
	    <div className="aspect panel">
		

		<div className="body">
	      {this.state.is_loading ? loader : null }
		<div className="rows">
		    {has_edit_perm ? (
			
			<div className="left-col">
			    <div className="winner read-write" onClick={this.handle_value_click}>
			    <div className="label">
				{this.get_value_text()}
			    </div>
			    	<span className="cf-count-btn"
				      data-balloon="Toon historie en details" data-balloon-pos="up"> {this.state.value_count}</span>
				
         		    </div>
			</div>
		    ) : (
			<div className="left-col">
			    <div className="winner readonly">
				<div className="label">
				    {this.get_value_text()}
				</div>
			    </div>
			</div>
			)}
		    {has_edit_perm || has_req_perm ? (
			<div className="right-col cfdrop" >
			     {this.render_dropdown()}
			</div>
		    ) : null }
		</div>
		
		<div className="remarks" >
		    {has_edit_perm ? (
			<div className="text-with-btn" onClick={this.handle_comment_click}>
			    {this.render_comments(has_edit_perm)}
			    <span className="cf-count-btn"
			data-balloon="Toon alle opmerkingen" data-balloon-pos="up"> {this.state.comment_count}</span>
			</div>
		    ) : 
			this.render_comments(has_edit_perm)
		    }
		</div>
		</div>		
	    </div>
	)
    }
}
/*
*/

export default StepAspect;
