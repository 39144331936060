import * as React from 'react'


import WpApi from '../wpapi'
import Loader from '../Loader'


import '../css/AanmeldFlow.css'
import UserRegistration from './UserRegistration'
import AvgHome from './AvgHome'
import SummaryTable  from './SummaryTable'

import Lightbox from './Lightbox'

import '../banyan/css/style.scss'
/*
  level:
  QL1 : 1e vragenlijst
  RL1 : 1e kassabon
  QL2 : 2e vragenlijst  incl kassabon

 */

/*
  devmode (boolean) : indicates if we are in localhost deployment 
  mode:
  - start :  show only QL1   yields a window.location redirect to mode=dash
  - classic : classic start ; dont redirect after QL1
  - dash  :  the rest of the flow   ; pickup inititially via URL-search-params provided in mode=start

 */
interface MyProps {
    devmode:boolean
    mode:string
}
type MyState = {
    loading:boolean
    fields:any
    level:string   // Ql1 
    summary:any
    message:string
    warning:string
    //registration:string
    values:any
}

class AanmeldFlow extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	private lightbox_registration =  React.createRef<Lightbox>()
    private lightbox_warnings = React.createRef<Lightbox>()


    constructor(props: MyProps) {
        super(props)



	this.validate = this.validate.bind(this)
	this.on_submit_click = this.on_submit_click.bind(this)
	this.field_change = this.field_change.bind(this)
	this.callback_after_register = this.callback_after_register.bind(this)
	
	this.state = {
	    loading:false,
	    level:"QL1",
	    summary:{},
	    message:'',
	    //registration:'',
	    warning:'',
	    values:{},
	    fields:[]
	}
    }
    componentDidMount(){
	let ls_values = localStorage.getItem('values')
	if(ls_values !== null){
	    this.setState({values: JSON.parse(ls_values)})
	}
	this.setState({loading:true})
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let path = '/aanmeldflow'
	let d : {[k:string]:any} = {
	    'init' : this.state.level
	}
	if(this.props.mode=="dash"){
	    // pickup URL params
	    let p = new URLSearchParams(window.location.search)
	    d['level'] = "dash-1"
	    d['postcode'] = p.get('postcode')
	    d['huisnummer'] = p.get('huisnummer')
	    d['huidig_aantal_bewoners'] = p.get("aantal_bewoners")
	    d['gasverbruik_schatting_l1'] = p.get("gasverbruik")
	    
	}
	this.fetchPromise = WpApi.do_get(path, d,(r) => {
	    this.setState({ fields: r.fields })
	    if(this.props.mode === "dash"){
		this.process_summary_data(r)
	    }
	})
    }
    field_change(e:any,tup:any){
	let trg = e.target
	console.log(`field_change ${trg.name} : ${trg.value}`)
	let k = trg.name

	let v = trg.value;
	if(k === 'postcode'){
	    v = v.toUpperCase()
	    v = v.replaceAll(' ','')
	}
	if(tup.cft_identifier === 'num-input'){
	    if(isNaN(Number(v))){
		return
	    }
	}
	const values = { ...this.state.values, [k]:v }
	localStorage.setItem('values', JSON.stringify(values));
	this.setState(() => ({ values }), ()=>{
	    if(this.state.level === 'QL2'){	
		this.refresh_summary_data()
	    }
	})
    }
    render_formfield(tup:any,i:number){
	let tuptype = tup.type
	if(tup.classes.includes('radio')){
	    tuptype = 'radio'
	}
	let typeclass : {[k:string]:string}= {
	    'text' : 'zw-input-text',
	    'radio' : 'zw-radio',
	    'select' : 'zw-dropdown'
	}
	let ts :string = tuptype
	let cls = tup.name + " " + typeclass[ts]
	if(tup.type === "message"){
	    if(tup.label.startsWith('introtekst')){
		return (
		    <p key={i} className="intro-text"> {tup.message}</p>
		)
	    }
	}

	return (
	    <div key={i} className={"hd-input-block simple-view "+cls}>
		<div className="label-container">
		<label> {tup.label} </label>
		{this.render_input(tuptype,tup)}
	        <span className="msg"></span>
		</div>
	    </div>
	)
    }
    render_input(tuptype:string,tup:any){
	if(tuptype === "text"){
	    return (
		<input type="text"
		       name={tup.name}
		       value={this.state.values[tup.name]}
		onChange={(e:any)=>{this.field_change(e,tup)}}
		    placeholder={tup.placeholder}
		    />
	    )
	}
	else if(tuptype === "radio") {
	    let val = this.state.values[tup.name]

	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => {
		let k1 = 'skip-option-'+k
		if(tup.classes !== undefined && tup.classes.includes(k1)){return null}
		let rid = tup.name + '-' + k
		return (
		    <div className="radio-item">
			<input id={rid} type="radio"
		    checked={k === val}
		          onChange={(e:any)=>{this.field_change(e,tup)}}
		          name={tup.name} value={k} />
			<label htmlFor={rid}> {lab} </label>
			</div>
		)
	    })
	    return (<div className="radio-items-container">{opt_list}</div>)
	}
	else if(tuptype === "select") {
	    let opt_list = Object.entries(tup.choices).map(([k,lab]:[string,any],i:number) => {
		let k1 = 'skip-option-'+k
		if(tup.classes !== undefined && tup.classes.includes(k1)){return null}
		return (
		    <option key={i} value={k}> {lab} </option>
		)
	    })
	    return (
		<select className="narrow"
			name={tup.name}
			value={this.state.values[tup.name]}
		onChange={(e:any)=>{this.field_change(e,tup)}}		    
		    >
		    <option>Selecteer</option>
		    {opt_list}
		</select>
	    )
	}
	else if(tup.type === "group"){
	    return (
		<div className="group">
		{tup.fields.map((e:any) => (
		    <div className="subfield">
			<div className="label">{e.label}</div>
			{this.render_input(e.type,e)}
		    </div>
		))}

		</div>
	    )
	}
    }
    
    render_form1(){
	if(!this.state.fields.level_1){
	    return null
	}
	return (
	    <div>
		{
		    this.state.fields.level_1.fields.map((field:any,i:number)=>
			this.render_formfield(field,i)
		    )
		}
		<div className="submit-knop" onClick={this.on_submit_click}>
		<a className="button button-next" >Doe "van het gas af" check </a>
		</div>
	    </div>
		
	)
    }


    render_form2(){
	if(!this.state.fields.level_2){
	    return null
	}
	//onClick={()=>{this.setState({registration:'start'})}}
	return (
	    <div>
		{
		    this.state.fields.level_2.fields.map((field:any,i:number)=>
			this.render_formfield(field,i)
		    )
		}
		<div className="submit-knop" onClick={this.on_submit_click}>
		<a className="button button-next" >Ik wil verder</a>
		</div>
		<div className="submit-knop" >
		
		{this.account_aanmaken()}
		</div>
	    </div>
		
	)
    }
    validate1(){
	let path = '/aanmeldflow'

	let d : {[k:string]:any} = {
	    values: this.state.values,
	    level: 'validate-1'
	}
	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    if(r.status === "ok"){
		console.log('ok')
		let path = this.props.devmode ?  '/hd-app.html?' : '/hd-app/?'
		let v = this.state.values
		let params = new URLSearchParams({
		    postcode: v.postcode,
		    huisnummer: v.huisnummer,
		    gasverbruik: v.gasverbruik_schatting_l1,
		    aantal_bewoners: v.huidig_aantal_bewoners
		})
		window.location.href = path + params.toString()

	    }else{
		this.setState({'warning':r.code})
		if(r.code !== ''){
		    this.lightbox_warnings.current?.open_me({})
		}
	    }
	})
    }
    refresh_summary_data(){
	let path = '/aanmeldflow'


	let d : {[k:string]:any} = {
	    values: this.state.values,
	    level: 1
	}
	if(this.state.level === 'QL2'){
	    d.level = 2
	    d.bag = {
		opp: this.state.summary.opp,
		adres: this.state.summary.adres,
		bouwlagen: this.state.summary.bouwlagen
	    }
	}
	
	WpApi.do_post(path, d,(r) => {
	    console.log(r)
	    if(r.status === "ok"){
		console.log('ok')
		this.process_summary_data(r)
	    }else{
		this.setState({'warning':r.code})
		if(r.code !== ''){
		    this.lightbox_warnings.current?.open_me({})
		}
	    }
	})
    }
    on_submit_click(e:any){
	console.log('submit '+this.state.level)
	this.setState({'message':''})
	let parent = e.target.closest('.zw-block')
	if(this.validate(parent) === true){
	    console.log('do http post')
	    if(this.props.mode === "start"){
		this.validate1()
	    }else{
		this.refresh_summary_data()
	    }
	}else{
	    console.log('validation failed')
	}
    }

    /*
     *  1e kassabon
     *   data: response from html-call
     */
    process_summary_data(data:any){
	console.log('show L1')
	console.log(data)
	let adr = ''
	
	if(this.state.level === 'QL1'){
	    this.setState({
		level: 'RL1',
	    })
	    adr = data.bag.result.straat + ' ' + data.bag.result.huisnummer
	    if(data.bag.result.huisletter){
		adr += ' ' + data.bag.result.huisletter
	    }
	    this.setState({
		summary: {
		    adres: adr,
		    bouwlagen: data.bag.bag3d.b3_bouwlagen,
		    opp: data.bag.extra.verblijfsobject.oppervlakte,
		    calc:  data.calc
		}
	    })
	}else{
	    // update summary state 
	    const summary = { ...this.state.summary, ['calc']:data.calc }
	    
	    this.setState(() => ({ summary }))
	}

	
    }
    validate(parent:Element) : boolean{
	if(this.state.level === 'QL1'){
	    let fields = this.state.fields.level_1.fields.filter((x:any) => x.type !== 'message')
	    let fieldnames = fields.map((x:any) => x.name)
	    let allgood = true
	    for(var x of fieldnames){
		let trg = parent.querySelector(`.hd-input-block.${x}`)
		if(this.state.values[x] === undefined ||
		    this.state.values[x] === "" 
		  ){
		    console.log(`oops ${x} is undef`)
		    if(trg!== null){
			trg.classList.add('warning')
		    }
		    allgood = false
		}else{
		    if(trg!== null){
			trg.classList.remove('warning')
		    }
		}
	    }
	    return allgood
	}
	return false
    }
    // screen area - right
    render_right(level:number){
	if(level === 1){
	    return (
    		<div className="content-area right">
                    <div className="zw-block zw-block-bg bg-dark">
			<h2>Doe de check</h2>
			{this.render_form1()}
		    </div>
		    <div className="message">{this.state.message}</div>
		</div>
	    )
	}
	else if(level === 2){
	    return (
		<div className="kassabon">
		    <SummaryTable data={this.state.summary}/>
		</div>
	    )
	}
    }

    callback_after_register(){
	console.log('callback after reg')
	this.lightbox_registration.current?.close_me({})
	//this.setState({registration:''})
    }
    // screen area - overlay
    render_warning(){
	let code = this.state.warning
	//if(code === ''){ return null}
	let txt = code
	let elm = (<div></div>)
	if(code === 'not-in-bag'){
	    elm = (<div> Dit adres konden we niet vinden </div>)
	}else if(code === 'existing-hd'){
	    elm = (
		<div>Er is al een Zonnewarmte account voor dit adres. <br/>
		    <a href="/wp-login.php">Klik hier om in te loggen</a>
		    </div>
	    )
	}
	
	
	return (    <Lightbox ref={this.lightbox_warnings} warning={this.state.warning}>{elm}</Lightbox>	)

    }
    // screen area - center
    render_main(level:number){
	if(level === 1){
	    if(this.state.level === "QL1" && this.state.fields.gemiddelde_woning !== undefined){

		let avgfields = this.state.fields.gemiddelde_woning.fields
		return (
		    <div className="content-area main">
			<div className="zw-block">
			    <AvgHome fields={avgfields}/>
			</div>
		    </div>
		)
	    }
	}else if(level === 2){
	    return (
		<div className="content-area main">
		    <div className="zw-block">
		    <h1>Meer vragen over jouw woning</h1>
		    {this.render_form2()}
		    </div>
		</div>
	    )
	}
    }
    // screen area - center + optionally right
    render_content(){
	if(this.state.level === 'QL1'){
	    return (
		<div className="layout-main layout-main-with-right">
		    {this.render_main(1)}
		    {this.render_right(1)}
		</div>
	    )
	}else if(this.state.level === 'QL2'){
	    return (
		<div className="layout-main layout-main-with-right">
		    {this.render_main(2)}
		    {this.render_right(2)}
		</div>
	    )
	}else if(this.state.level === 'RL1'){
	    // kassabon standalone midden op pagina
	    return (
		<div className="layout-main layout-narrow">
		    <div className="content-area-main">
		    <div className="zw-block zw-block-bg bg-white desktop-border-medium-light medium-space-below">
			<div className="confirmation-message positive">
			    <h1 className="smaller">Je kunt zonder voorbehoud meedoen aan Zonnewarmte Ramplaankwartier!</h1>
			</div>

			<SummaryTable data={this.state.summary}/>
		    </div>
		    <div className="buttons-row full-width extra-big-space-below">
			<h5>Maak je keuze </h5>
			<a onClick={()=> { this.setState({'level': 'QL2'})}} className="button button-next" >Ik wil misschien wel meedoen</a>
			{this.nieuwsbrief()}
			</div>
		    </div>
		    </div>
	    )
	}
    }
    account_aanmaken(){
	return (
	    <Lightbox btn_txt="Oplaan ingevulden gegevens" ref={this.lightbox_registration}>
	    	<UserRegistration cb_parent={this.callback_after_register} />
	    </Lightbox>
	)
    }
    nieuwsbrief(){
	return (
	    <Lightbox btn_txt="Ik schrijf me in voor de nieuwsbrief">
		    <h3 className="lightbox-title">Ik schrijf me in voor de nieuwsbrief</h3>
		    <div className="hd-input-block simple-view text">
			<div className="label-container">
			    <label htmlFor="jouw-naam">Jouw naam</label>
			</div>
			<input id="jouw-naam" type="text" placeholder="" className="medium-wide" />
		    </div>
		    <div className="hd-input-block simple-view email">
			<div className="label-container">
			    <label htmlFor="jouw-mail">Jouw e-mail</label>
			</div>
			<input id="jouw-e-mail" type="email" placeholder="" className="medium-wide"  />
		    </div>
		    <div className="hd-input-block simple-view phone">
			<div className="label-container">
			    <label htmlFor="jouw-telefoon">Jouw telefoonnummer<span className="optional">(optioneel)</span></label>
			</div>
			<input id="jouw-telefoon" type="tel" placeholder="" className="medium-wide" />
		    </div>
		    <div className="hd-input-block horizontal-view checkbox">
			<input id="nschrijven-nieuwsbrief" type="checkbox" name="nschrijven-nieuwsbrief" value="inschrijven-nieuwsbrief" />
			<div className="label-container">
			    <label htmlFor="inschrijven-nieuwsbrief">Ik ontvang circa 2 x per maand een e-mail met update over Zonnewarmte Ramplaankwartier </label>
			</div>                            
		    </div>
		    <button  className="button button-open button-next less-height smaller-text">Hou me op de hoogte</button>
		</Lightbox>
	)
    }
    render(){
	return (
	    <div id="main" className="main-regular">
		{this.render_content()}
	        {this.render_warning()}
	    </div>
	)
    }
}

export default AanmeldFlow

