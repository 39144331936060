import * as React from 'react'

import WpApi from './wpapi'
import './css/RmInvoer.css'

import RmInvoerField from './RmInvoerField'

interface MyProps {
    post_id:number
}
type MyState = {
    sections:any
    tech:any
    msg:string
}

class RmInvoer extends React.PureComponent<MyProps,MyState> {
    private fetchPromise ?: Promise<void>
	
    constructor(props: MyProps) {
        super(props)
	this.state = {
	    msg: '',
	    sections: {},
	    tech:{}
	}
    }
    init(){
	if (this.fetchPromise) {
	    console.log(' already mounted previously')
	    return;
	}
	let d = {
	    view: 'rm_invoer',
	    post_id: this.props.post_id.toFixed()
	    
	}
	let path = '/calc'

	this.fetchPromise = WpApi.do_get(path, d,(r:any) => {
	    if(r.code === "rest_forbidden"){
		this.setState({msg: 'No access. '+r.message})
	    }else{
		this.setState({
		    sections: r.sections,
		    tech: r.tech_settings
		})
	    }
	})
    }
    componentDidMount(){
	console.log('did mount')
	this.init()
    }
    show_row(row:any){
	if(row.classes.includes('zonder-invoer')){
	    return false
	}
	return true
    }
    show_field(row:any){
	return row.cft_identifier.includes("input")

    }
    render_field(tup:any,i:number){
	return this.show_field(tup) ?  (
		<RmInvoerField  key={tup.slug} tup={tup}/>
	) : null
    }
    render_body(rows:Array<any>){
	return (
	    <div className="body">
		{rows.map((tup:any,i:number)=> this.render_field(tup,i))}
	    </div>
	)
    }
    render_row(row:any,i:number){
	return (
	    <div key={i} className="section">
		<div className="header">{row.label}</div>
		{this.render_body(row.steps)}
	    </div>
		
	)
    }
    render_sections(){
	return Object.entries(this.state.sections).map(([k,row]:[string,any], i:number) => {
	    return this.show_row(row) ? this.render_row(row,i) : null
	})
    }

    render(){
	return (
	    <div className="rm-invoer">
		<h3>Invoer simpel</h3>
		{this.render_sections()}
	    </div>
	)
    }
}

export default RmInvoer
